@charset "UTF-8";
/***
    The new CSS reset - version 1.7.3 (last updated 7.8.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio, svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
  - fix for the content editable attribute will work properly.
  - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element */
:where([contenteditable]:not([contenteditable=false])) {
  -webkit-line-break: after-white-space;
  overflow-wrap: break-word;
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable=true]) {
  -webkit-user-drag: element;
}

/**
 * ルートフォントサイズpx（ブラウザ標準フォントサイズ）
 */
/**
 * ベースフォントサイズpx
 */
/**
 * ベース行の高さ
 */
/**
 * メディアクエリブレイクポイント Tailwind202303時点基準
 */
/**
 * メインコンテンツ幅
 */
/**
 * pxをremで返す
 *
 * @function rem
 * @param {number} $number pxサイズ
 * @var {number} $root-font-size ブラウザ標準フォントサイズ（16px）
 * @return {number} 単位ありremサイズ
 * @example
 *    $root-font-size : 16px の場合
 *    rem(10) 10px => 0.625rem
 *    rem(16) 16px => 1rem
 *    rem(24) 24px => 1.5rem
 *    rem(32) 32px => 2rem
 */
/**
 * 単位を除いた数値を返す
 *
 * @function strip-unit
 * @param {number} $value 単位あり数値
 * @return {number} 単位なし数値
 * @example
 *    strip-unit(16px) => 16
 *    strip-unit(1rem) => 1
 */
/**
 * clampを使用した可変サイズ指定関数
 *
 * @function fluid
 * @param {number} $min-px 最小サイズpx
 * @param {number} $max-px 最大サイズpx
 * @param {number} $min-viewport-px 最小ビューポートpx
 * @param {number} $max-viewport-px 最大ビューポートpx
 * @var {number} $min 最小サイズ
 * @var {number} $max 最大サイズ
 * @return {string} clamp
 * @example
 *    fluid(16, 32) => clamp(1rem, 1.67vw + 0.667rem, 2rem)
 *      320pxから1280pxの間で16pxから32pxまでサイズが変わる
 *    fluid(10px, 18px, 480px, 1024px) => clamp(0.625rem, 1.47vw + 0.184rem, 1.125rem)
 *      480pxから1024pxの間で10pxから18pxまでサイズが変わる
 */
/**
 * メディアクエリmixin
 *
 * @mixin mq
 * @param {number} $size ブレイクポイント（単位あり）
 * @param {string} $width max or min セット defalut max
 * @param {bool} or {string} $orientation(false, portrait, landscape)
 * @example
 *    @include mq(320px) { => @media (max-width:320px) {
 *    @include mq(480px, min, portrait) { => @media (min-width:480px) and (orientation: portrait)
 */
/**
 * clearfix mixin
 * float回り込み解除処理
 *
 * @mixin clearfix
 */
/**
 * px=>rem 変換してフォントサイズ指定 mixin
 *
 * @mixin font-size
 * @param {number} $px 単位なしpxサイズ
 */
/**
 * 可変フォントサイズ mixin
 *
 * @mixin fluid-font-size
 * @param {number} $min-px 最小サイズpx
 * @param {number} $max-px 最大サイズpx
 * @param {number} $min-viewport-px 最小ビューポートpx
 * @param {number} $max-viewport-px 最大ビューポートpx
 */
/**
 * 長文省略 mixin
 *
 * @mixin line-clamp
 * @param {number} $lines 表示行数
 */
/**
 * アイコンフォント mixin
 *
 * @mixin icon-font
 * @param {string} $code 文字コード（エスケープ文字￥不要）
 * @param {number} $px pxサイズ
 * @param {number} $weight フォントウェイト
 * @param {string} $font-family フォントファミリー
 * @use @include icon-font("f0c9", 16, 400, Font Awesome 6 Pro);
 */
:root {
  --base-font-family: "Roboto", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  --base-font-size: 16;
  --base-color: #dfdfdf;
  --base-font-color: #000;
  --border-color: #ccc;
  --main-color: #000;
  --sub-color: #099;
  --accent-color: #c00;
  --bg-color: #eee;
  --bg-sub-color: #eee;
  --link-color: #099;
  --syg-sub-color: #d61518;
  --sweetyears-sub-color: #ce0e2d;
  --nero-sub-color: #D0D521;
}

body {
  font-family: var(--base-font-family);
  font-size: 1rem;
  line-height: 1.6;
  background: #eee;
}
body.is-active {
  overflow: hidden;
}

a * {
  transition: opacity 0.4s ease-in-out;
}
a:hover *:not(img) {
  opacity: 0.7;
}

/**
  * ヘッダー
  */
.header {
  position: fixed;
  z-index: 9995;
  width: 100%;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  background: #fff;
  mix-blend-mode: difference;
}

.header-navset {
  display: flex;
  align-items: center;
}
@media (max-width: 639.999px) {
  .header-navset {
    display: block;
  }
}

.sitename {
  background: #000;
  padding: clamp(0.5rem, 1.875vw + -0.25rem, 1.25rem) clamp(0.5rem, 1.875vw + -0.25rem, 1.25rem) clamp(0.25rem, 1.875vw + -0.5rem, 1rem);
  line-height: 1;
  width: clamp(5rem, 12.5vw + 0rem, 10rem);
}
.sitename .logo path {
  fill: #fff;
}

.modal__bk {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.modal__bk.is-active {
  visibility: visible;
  opacity: 1;
}

.modal__inner._search {
  color: #fff;
  z-index: 9999;
}

.modal-search__form {
  display: flex;
  align-items: center;
  gap: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  margin-bottom: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.modal-search__form.is-active {
  visibility: visible;
  opacity: 1;
}

.modal-search__input {
  width: 70vw;
  padding: clamp(0.3125rem, 0.78125vw + 0rem, 0.625rem);
  border-bottom: solid 2px #fff;
  font-size: 1rem;
}

.modal-search__btn {
  cursor: pointer;
  line-height: 1;
}

.modal-search__close {
  cursor: pointer;
  border: solid 2px #fff;
  border-radius: 50%;
  line-height: 1;
  display: grid;
  place-items: center;
  width: clamp(1.875rem, 1.5625vw + 1.25rem, 2.5rem);
  height: clamp(1.875rem, 1.5625vw + 1.25rem, 2.5rem);
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.4s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.modal-search__close.is-active {
  opacity: 0.5;
}
.modal-search__close .material-symbols-outlined {
  font-size: clamp(0.75rem, 1.25vw + 0.25rem, 1.25rem);
}

.header-nav {
  margin-left: clamp(0.625rem, 4.6875vw + -1.25rem, 2.5rem);
}
@media (max-width: 639.999px) {
  .header-nav {
    position: absolute;
    padding: 10px 0 5px;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    background: #000;
    color: #fff;
  }
}

@media (max-width: 639.999px) {
  .header-nav__block {
    overflow-y: scroll;
    width: 100vw;
    height: 18px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .header-nav__block::-webkit-scrollbar {
    display: none;
  }
}

.header-nav__inner {
  display: flex;
  gap: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  font-size: clamp(0.875rem, 1.5625vw + 0.25rem, 1.5rem);
  font-weight: 900;
  line-height: 1.2;
  white-space: nowrap;
}
.header-nav__inner .basic__link:after {
  background: #000;
}
@media (max-width: 639.999px) {
  .header-nav__inner {
    width: 180%;
    justify-content: space-around;
    gap: 20px;
    font-size: clamp(0.625rem, 1.875vw + 0.25rem, 1rem);
  }
  .header-nav__inner .basic__link:after {
    background: #fff;
  }
}

@media (min-width: 640px) {
  .header-nav__item._sub {
    display: none;
  }
}

.header-btnset {
  display: flex;
  align-items: center;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #000;
}
.header-btn {
  line-height: 1;
  cursor: pointer;
  height: 100%;
  margin-right: clamp(0.5rem, 1.875vw + -0.25rem, 1.25rem);
  display: grid;
  place-items: center;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header-btn .material-symbols-outlined {
  font-variation-settings: "wght" 300;
}
.header-btn._search .material-symbols-outlined {
  font-size: clamp(1.375rem, 2.5vw + 0.375rem, 2.375rem);
  font-variation-settings: "wght" 400;
}
.header-btn._cart .header-btn__inner {
  position: relative;
}
.header-btn._cart .icon-cart__num {
  position: absolute;
  transform: translate(40%, -40%);
  top: 0;
  right: 0;
  width: clamp(0.9375rem, 0.78125vw + 0.625rem, 1.25rem);
  height: clamp(0.9375rem, 0.78125vw + 0.625rem, 1.25rem);
  border-radius: 50%;
  background: var(--accent-color);
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.header-btn._cart .material-symbols-outlined {
  font-size: clamp(1.375rem, 2.5vw + 0.375rem, 2.375rem);
}
.header-btn._mypage .material-symbols-outlined {
  font-size: clamp(1.625rem, 3.125vw + 0.375rem, 2.875rem);
}

.gnav-btn {
  padding: 0 5px;
}
.gnav-btn .gnav-btn__inner {
  width: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
}
.gnav-btn .gnav-btn__line {
  display: block;
  transition: margin 0.2s 0.2s, transform 0.2s;
  height: 2px;
  border-radius: 3px;
  background-color: #000;
}
.gnav-btn .gnav-btn__line:nth-of-type(1), .gnav-btn .gnav-btn__line:nth-of-type(2) {
  margin-bottom: 2px;
}
.gnav-btn.is-active .gnav-btn__line {
  transition: margin 0.2s, transform 0.2s 0.2s;
}
.gnav-btn.is-active .gnav-btn__line:nth-of-type(1) {
  transform: rotate(-45deg);
  margin-bottom: -3px;
}
.gnav-btn.is-active .gnav-btn__line:nth-of-type(2) {
  opacity: 0;
}
.gnav-btn.is-active .gnav-btn__line:nth-of-type(3) {
  transform: rotate(45deg);
  margin-top: -3px;
}

.gnav {
  position: fixed;
  background: #000;
  color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 9990;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  overscroll-behavior-y: contain;
}
.gnav.is-active {
  transform: translateX(0);
  transition: transform 0.4s 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.gnav__block {
  overflow-y: scroll;
  padding: clamp(3.75rem, 3.125vw + 2.5rem, 5rem) clamp(0.625rem, 7.8125vw + -2.5rem, 3.75rem) 160px;
  height: 100%;
}

.gnav__inner {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: clamp(0.625rem, 4.6875vw + -1.25rem, 2.5rem);
}
.gnav__subtitle + .gnav__inner {
  margin-top: 10px;
}

.gnav__subtitle {
  margin-top: 20px;
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  display: flex;
  align-items: center;
  font-weight: 900;
}
.gnav__subtitle:after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 1px;
  background: #fff;
  margin-left: clamp(0.25rem, 0.625vw + 0rem, 0.5rem);
}

.gnav__item {
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  font-weight: 900;
}
.gnav__item._cart {
  position: relative;
  line-height: 1.1;
}
.gnav__item._cart .material-symbols-outlined {
  font-variation-settings: "wght" 300;
}
.gnav__item._cart .icon-cart__num {
  position: absolute;
  transform: translate(40%, -40%);
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--accent-color);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gnav__icon-sns svg {
  width: 20px;
  height: 20px;
}
.gnav__icon-sns path,
.gnav__icon-sns circle {
  fill: #fff;
}

.main {
  padding-top: clamp(2.625rem, 7.5vw + -0.375rem, 5.625rem);
}
@media (max-width: 639.999px) {
  .main {
    padding-top: 75px;
  }
}
.index .main {
  padding-top: 0;
}

.mainimg {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: clamp(2.625rem, 7.5vw + -0.375rem, 5.625rem);
}
@media (max-width: 639.999px) {
  .mainimg {
    padding-top: 75px;
  }
}

.mainimg__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swiper-slide {
  display: block;
}

.swiper-horizontal {
  touch-action: auto !important;
}

.swiper-slide.swiper-slide-active .swiper-img img {
  transform: scale(1.1) translateX(-4%);
}

.swiper-img img {
  object-fit: cover;
  transition: transform 6s ease-in;
}

.main-logo {
  width: 30%;
  margin-left: clamp(0.625rem, 1.5625vw + 0rem, 1.875rem);
  mix-blend-mode: difference;
  z-index: 2;
  animation: slide-rotate-hor-bottom 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (max-width: 639.999px) {
  .main-logo {
    width: 23%;
  }
}
.main-logo path {
  stroke: #fff;
  transition: fill 0.2s 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.main-logo.is-active path {
  fill: #fff;
}

@keyframes slide-rotate-hor-bottom {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.mainimg__slider {
  position: relative;
  width: 80%;
  max-width: 1400px;
  z-index: 1;
  margin-right: 0 !important;
  margin-left: -10% !important;
  line-height: 1;
}
@media (max-width: 639.999px) {
  .mainimg__slider {
    width: 88%;
  }
}

.mainimg__slider-link {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  transform: translate(-50%, 50%) skew(45deg, -45deg) scale(60%);
}
@media (max-width: 639.999px) {
  .mainimg__slider-link {
    transform: translate(-50%, 50%) skew(45deg, -45deg) scale(30%);
  }
}
.mainimg__slider-link a {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.mainimg__slider-link a.is-active {
  opacity: 1;
  pointer-events: auto;
}
.mainimg__slider-link svg {
  width: 48px;
  height: 48px;
  animation: flow 1.8s ease-in-out 0s infinite;
}

@keyframes flow {
  0% {
    transform: translate(-4%, 0);
  }
  50% {
    transform: translate(12%, 0);
  }
  100% {
    transform: translate(-4%, 0);
  }
}
.swiper__mainimg {
  overflow: hidden;
  aspect-ratio: 16/9;
}
.swiper__mainimg.is-active {
  cursor: pointer;
}
.swiper__mainimg img {
  width: 100%;
}

.progress {
  position: absolute;
  transform: rotate(-90deg) translate(-50%, -50%) scale(1);
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 84px;
  height: 84px;
}
@media (max-width: 639.999px) {
  .progress {
    transform: rotate(-90deg) translate(-50%, -50%) scale(0.4);
  }
}
.progress circle {
  fill: transparent;
  stroke: rgba(0, 0, 0, 0.8);
  stroke-width: 2;
  stroke-dasharray: 0 252;
}
.progress circle.is-init {
  animation: circle 5s linear infinite;
}
.progress circle.is-active {
  animation: circle 6s linear infinite;
}

.progress__bg {
  position: absolute;
  transform: rotate(-90deg) translate(-50%, -50%) scale(1);
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 84px;
  height: 84px;
}
@media (max-width: 639.999px) {
  .progress__bg {
    transform: rotate(-90deg) translate(-50%, -50%) scale(0.4);
  }
}
.progress__bg circle {
  fill: transparent;
  stroke: rgba(255, 255, 255, 0.6);
  stroke-width: 2;
  stroke-dasharray: 252 252;
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 252;
  }
  99.9%, to {
    stroke-dasharray: 252 252;
  }
}
.logo-scroll {
  mix-blend-mode: difference;
  position: absolute;
  overflow: hidden;
  margin: 0 calc(35% - 50vw);
  width: 100vw;
  display: flex;
  align-items: center;
  bottom: -20%;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.4s 0.6s ease-in-out;
}
@media (max-width: 639.999px) {
  .logo-scroll {
    margin: 0 calc(40% - 50vw);
  }
}
.logo-scroll.is-active {
  opacity: 1;
}

.logo-scroll__inner {
  display: flex;
  align-items: center;
  animation: loop infinite linear 17s both;
}
.logo-scroll__inner .logo-scroll__child {
  width: 100vw;
  padding: 0 0.5vw;
  height: auto;
}
.logo-scroll__inner .logo-scroll__child path {
  fill: #fff;
}

@keyframes loop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.thumb-block {
  position: relative;
  z-index: 4;
  background: #000;
  width: 45%;
  margin: 0 0 0 auto;
  transform: translateY(-60%);
}
@media (max-width: 639.999px) {
  .thumb-block {
    width: 60%;
  }
}

.swiper__thumb {
  overflow: hidden;
  display: none;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}
.swiper__thumb.swiper-initialized {
  opacity: 1;
  display: block;
}
.swiper__thumb .swiper-slide {
  opacity: 0.5;
  line-height: 0;
  transition: opacity 0.8s ease-in-out;
}
.swiper__thumb .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}
.swiper__thumb .swiper-horizontal > .swiper-scrollbar,
.swiper__thumb .swiper-scrollbar.swiper-scrollbar-horizontal {
  position: relative !important;
  height: clamp(0.25rem, 0.625vw + 0rem, 0.5rem) !important;
  bottom: 0 !important;
}
.swiper__thumb .swiper-scrollbar-drag {
  height: 10% !important;
  position: relative;
  background: #fff !important;
  border-radius: 0 !important;
  top: 40% !important;
}

.topselling__block {
  overflow: hidden;
  margin-top: 0 !important;
}

.swiper__top-item .swiper-slide {
  overflow: visible !important;
}
.swiper__top-item .swiper-slide::before {
  counter-increment: rank;
  content: "#" counter(rank, decimal-leading-zero);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transform: translate(-25%, -50%);
  font-size: clamp(1.125rem, 0.9375vw + 0.75rem, 1.5rem);
  font-weight: 900;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  color: #fff;
}

#topImage {
  display: none;
}

.category__img img {
  width: 100%;
  height: auto;
}

.check__block {
  overflow: hidden;
}
.check__block + .about__block {
  margin-top: 0;
}

.brand__block {
  padding-bottom: 0 !important;
}

.brand-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
@media (max-width: 639.999px) {
  .brand-list {
    padding-bottom: 20px;
  }
}
.brand-list .brand-list__item {
  padding: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 0 clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
  width: 20%;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 639.999px) {
  .brand-list .brand-list__item {
    width: 50%;
  }
}
.brand-list .brand-list__item .basic__btn-border {
  margin-top: auto;
}
.brand-list .brand-list__item._pallaetonda .brand-list__title {
  margin-bottom: 20px;
}
.brand-list .brand-list__item._pallaetonda svg {
  width: 60%;
  height: auto;
}
.brand-list .brand-list__item._syg .brand-list__title {
  margin-bottom: 20px;
}
.brand-list .brand-list__item._syg svg {
  width: 62%;
  height: auto;
}
.brand-list .brand-list__item._sweetyears .brand-list__title {
  margin-top: clamp(1.25rem, 4.6875vw + -0.625rem, 3.125rem);
  margin-bottom: 20px;
}
.brand-list .brand-list__item._sweetyears svg {
  width: 70%;
  height: auto;
}
.brand-list .brand-list__item._nero .brand-list__title {
  margin-top: clamp(1.25rem, 4.6875vw + -0.625rem, 3.125rem);
  margin-bottom: 20px;
}
.brand-list .brand-list__item._nero svg {
  width: 70%;
  height: auto;
}
@media (max-width: 639.999px) {
  .brand-list .brand-list__item._pace {
    width: 60%;
  }
}
.brand-list .brand-list__item._pace .brand-list__title {
  margin-top: clamp(0.9375rem, 2.34375vw + 0rem, 1.875rem);
  margin-bottom: 20px;
}
.brand-list .brand-list__item._pace svg {
  width: 60%;
  height: auto;
}
.brand-list .brand-list__item svg {
  fill: #fff;
}
.brand-list .brand-list__item svg .logo-syg__sub-color {
  fill: var(--syg-sub-color);
}
.brand-list .brand-list__item svg .logo-sweetyears__sub-color {
  fill: var(--sweetyears-sub-color);
}
.brand-list .brand-list__item svg .logo-nero__sub-color {
  fill: var(--nero-sub-color);
}

.store__inner {
  margin: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem) auto 0;
  max-width: 750px;
}
@media (max-width: 767.999px) {
  .store__inner {
    margin: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem) 10px 0;
  }
}

.store__img {
  line-height: 0;
  width: 100%;
  height: auto;
}

.store__contents {
  color: #fff;
  background: #000;
  padding: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}

.store__text dl {
  display: flex;
  font-size: 0.75rem;
}
.store__text dl dt {
  font-weight: 900;
}
.store__text dl dt::after {
  content: ":";
  margin: 0 5px;
}
.store__text dl dd {
  font-weight: 900;
}
.store__text small {
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
  line-height: 1.2;
}

.about__block {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: #000;
}
@media (max-width: 639.999px) {
  .about__block {
    flex-direction: column;
  }
}

.about__contents {
  color: #fff;
  width: 40%;
  padding-left: clamp(0.625rem, 1.5625vw + 0rem, 1.875rem);
  font-size: clamp(0.75rem, 0.3125vw + 0.625rem, 0.875rem);
}
.about__contents .basic__title {
  margin-top: 0;
  mix-blend-mode: difference;
}
.about__contents .sns__block .icon-sns svg {
  width: 20px;
  height: 20px;
}
.about__contents .sns__block .icon-sns path,
.about__contents .sns__block .icon-sns circle {
  fill: #fff;
}
.about__contents .sns__inner {
  justify-content: left;
  margin-top: 10px;
}
@media (max-width: 639.999px) {
  .about__contents {
    width: 100%;
    padding: 20px 20px 0;
    margin-top: -60px;
  }
  .about__contents .basic__title {
    text-align: center !important;
  }
  .about__contents .sns__inner {
    justify-content: center;
  }
}

.about__logo {
  width: clamp(6.25rem, 15.625vw + 0rem, 12.5rem);
  z-index: 2;
}
.about__logo path {
  fill: #fff;
}
@media (max-width: 639.999px) {
  .about__logo {
    margin: 5px auto 0;
  }
}

.about__text {
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  mix-blend-mode: difference;
}

.about__video {
  width: 70%;
  margin-left: -10%;
  line-height: 0.7;
}
@media (max-width: 639.999px) {
  .about__video {
    width: 100%;
    margin-left: 0;
  }
}
.about__video video {
  width: 100%;
  object-fit: fill;
  aspect-ratio: 16/9;
}

.sns__block .icon-sns svg {
  width: 20px;
  height: 20px;
}
.sns__block .icon-sns path,
.sns__block .icon-sns circle {
  fill: #000;
}

.sns__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}

.footer {
  background: #000;
  color: #fff;
}

.footer__block {
  margin: 0 auto;
  padding: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem) clamp(0.625rem, 7.8125vw + -2.5rem, 3.75rem);
}

.footer__header {
  display: flex;
  align-items: center;
}
.index .footer__header {
  display: none;
}
.footer__header .sns__block {
  margin-left: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}
.footer__header .sns__block .icon-sns svg {
  width: 20px;
  height: 20px;
}
.footer__header .sns__block .icon-sns path,
.footer__header .sns__block .icon-sns circle {
  fill: #fff;
}

.footer__logo svg {
  width: clamp(6.25rem, 15.625vw + 0rem, 12.5rem);
  height: auto;
  fill: #fff;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
  gap: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.index .footer__inner {
  margin-top: 0;
}
@media (max-width: 639.999px) {
  .footer__inner > * {
    width: calc((100% - 20px) / 2);
  }
}
.footer__inner dl {
  display: flex;
}
.footer__inner dl dt::after {
  content: ":";
  margin: 0 5px;
}
.footer__inner li > a::before {
  display: inline-block;
  vertical-align: middle;
  font-family: "Material Symbols Outlined";
  font-weight: 400;
  content: "\e5cc";
  font-size: 1rem;
}

.footer__title {
  font-weight: 900;
}

.copyright {
  font-size: 0.75rem;
  text-align: center;
  margin-top: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
  font-weight: 900;
}

.basic__block {
  margin-top: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
}
.main .basic__block:first-child {
  margin-top: 0;
  padding-top: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
}
.basic__block > *:first-child {
  margin-top: 0;
}
.basic__block._bg + .basic__block._bg {
  margin-top: 0;
}
.basic__block._bg {
  padding: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem) 0;
  background: #000;
  color: #fff;
}
.basic__block._bg-white {
  background: #fff;
  color: #000;
}
.basic__block._bg-gray {
  background: var(--base-color);
  color: #000;
}

.basic__title {
  font-weight: 900;
  font-size: clamp(1.125rem, 5.625vw + -1.125rem, 3.375rem);
  text-align: center;
  overflow: hidden;
  line-height: 1.2;
  margin-top: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
}
.basic__title span,
.basic__title small,
.basic__title svg {
  display: block;
  transform: translateY(200%);
  transition: transform 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.basic__title.is-active span,
.basic__title.is-active small,
.basic__title.is-active svg {
  transform: translateY(0);
}
.basic__title._left {
  text-align: left;
}

.basic__catch {
  font-size: clamp(1rem, 3.125vw + -0.25rem, 2.25rem);
}

.basic__subtitle {
  font-size: clamp(0.625rem, 1.25vw + 0.125rem, 1.125rem);
  margin-top: -3vw;
}
@media (max-width: 1279.999px) {
  .basic__subtitle {
    margin-top: -3.7vw;
  }
}
@media (max-width: 1023.999px) {
  .basic__subtitle {
    margin-top: -3.5vw;
  }
}
@media (max-width: 639.999px) {
  .basic__subtitle {
    margin-top: -20px;
  }
}

.basic__btn {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  text-align: center;
}
.basic__btn a {
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  display: inline-block;
  min-width: 60%;
  vertical-align: middle;
  font-weight: 900;
  padding: clamp(0.625rem, 0.78125vw + 0.3125rem, 0.9375rem) clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  transition: background-color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), border 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
  background: #000;
  color: #fff;
  border: 2px solid #000;
}
.basic__btn a:hover {
  border: 2px solid currentColor;
  color: #000;
  background: transparent;
}
._accent .basic__btn a:hover {
  border: 2px solid var(--accent-color);
}
.basic__btn._accent a {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}
.basic__btn._accent a:hover {
  background: transparent;
  border: 2px solid var(--accent-color);
  color: #000;
}

.basic__btn-border {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  text-align: center;
}
.basic__btn-border a {
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  display: inline-block;
  min-width: 60%;
  vertical-align: middle;
  font-weight: 900;
  padding: clamp(0.625rem, 0.78125vw + 0.3125rem, 0.9375rem) clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  transition: background-color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), border 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
  color: #fff;
  border: 2px solid currentColor;
}
.basic__btn-border a:hover {
  border: 2px solid #fff;
  color: #000;
  background: #fff;
}
.basic__btn-border._reverse a {
  color: #000;
  border: 2px solid currentColor;
}
.basic__btn-border._reverse a:hover {
  border: 2px solid #000;
  color: #fff;
  background: #000;
}

.basic__link {
  position: relative;
  display: inline-block;
  transition: color 0.2s;
}
.basic__link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s;
}
.item-list .basic__link:after, .sns__item .basic__link:after {
  background: #000;
}
.basic__link._black:after {
  background: #000;
}
.basic__link:hover:after {
  transform: scaleX(1);
}

.basic__contents {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) clamp(0.3125rem, 11.71875vw + -4.375rem, 5rem) 0;
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.basic__contents h2,
.basic__contents h3,
.basic__contents h4,
.basic__contents h5,
.basic__contents h6 {
  font-weight: 900;
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
}
.basic__contents h2 {
  font-size: clamp(1.125rem, 4.6875vw + -0.75rem, 3rem);
}
.basic__contents h3 {
  font-size: clamp(1rem, 3.4375vw + -0.375rem, 2.375rem);
  background: #000;
  color: #fff;
  padding: clamp(0rem, 0.78125vw + -0.3125rem, 0.3125rem) clamp(0.9375rem, 0.78125vw + 0.625rem, 1.25rem);
}
.basic__contents h4 {
  font-size: clamp(1rem, 3.4375vw + -0.375rem, 2.375rem);
  border-bottom: solid 1px var(--border-color);
}
.basic__contents h5 {
  font-size: clamp(1rem, 3.4375vw + -0.375rem, 2.375rem);
}
.basic__contents h6 {
  font-size: clamp(1rem, 1.875vw + 0.25rem, 1.75rem);
}
.basic__contents ul {
  list-style: disc;
  margin-left: 20px;
}
.basic__contents ol {
  margin-left: 15px;
}
.basic__contents ol li {
  list-style: decimal;
}
.basic__contents table {
  width: 100%;
}
.basic__contents table th {
  white-space: nowrap;
  font-weight: 900;
  background: var(--base-color);
}
.basic__contents table th,
.basic__contents table td {
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
  padding: clamp(0.625rem, 0.78125vw + 0.3125rem, 0.9375rem) clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  border: solid 1px var(--border-color);
}
.basic__contents > * {
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}
.basic__contents dt {
  font-weight: 900;
}
.basic__contents strong {
  font-weight: bold;
}

.pager {
  display: flex;
  justify-content: center;
}

.pager__btn {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  text-align: center;
}
.pager__btn > * {
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  padding: clamp(0.625rem, 0.78125vw + 0.3125rem, 0.9375rem) 0;
  width: clamp(2.5rem, 3.125vw + 1.25rem, 3.75rem);
  height: clamp(2.5rem, 3.125vw + 1.25rem, 3.75rem);
  transition: background-color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), border 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.pager__btn._first, .pager__btn._last {
  line-height: 0.7;
}
.pager__btn a {
  background: #000;
  color: #fff;
  border: 2px solid #000;
}
.pager__btn a:hover {
  border: 2px solid currentColor;
  color: #000;
  background: transparent;
}
.pager__btn .material-symbols-outlined {
  font-size: clamp(1.125rem, 1.5625vw + 0.5rem, 1.75rem);
  font-variation-settings: "wght" 400;
  line-height: 1;
}

.subcategory-list {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 20px 0;
  padding: 10px 10px 0;
  background: #000;
  color: #fff;
}
@media (max-width: 767.999px) {
  .subcategory-list {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 10px 0;
  }
}
@media (max-width: 639.999px) {
  .subcategory-list {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 5px 0;
    padding: 5px 5px 0;
    font-size: 0.75rem;
  }
}
.subcategory-list li {
  margin: 0 10px 10px;
  display: inline-block;
}
@media (max-width: 639.999px) {
  .subcategory-list li {
    margin: 0 2.5px 5px;
  }
}
.subcategory-list li a {
  text-decoration: underline;
}

.subcategory-list__title {
  font-weight: bold;
}

.items-control {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media (max-width: 767.999px) {
  .items-control {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 10px 0;
  }
}
@media (max-width: 639.999px) {
  .items-control {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 5px 0;
    font-size: 0.75rem;
  }
}

.items-control__total ._number {
  font-weight: 900;
  font-size: 1.4em;
}

.items-control__sort dt::after {
  content: " : ";
}
.items-control__sort dt,
.items-control__sort dd,
.items-control__sort li {
  display: inline-block;
}
.items-control__sort li span {
  text-decoration: underline;
  font-weight: 900;
}
.items-control__sort li a {
  transition: opacity 0.4s ease-in-out;
}
.items-control__sort li a:hover {
  opacity: 0.7;
}

.item {
  display: flex;
  justify-content: center;
  gap: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 20px 0;
}
@media (max-width: 767.999px) {
  .item {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 10px 0;
  }
}
@media (max-width: 639.999px) {
  .item {
    display: block;
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 5px 0;
  }
}

.item__img {
  width: 50%;
  max-width: 500px;
}
@media (max-width: 767.999px) {
  .item__img {
    max-width: none;
  }
}
@media (max-width: 639.999px) {
  .item__img {
    width: 100%;
  }
}
.item__img .swiper__item-main {
  position: relative;
}
.item__img .swiper__item-main .swiper-wrapper {
  overflow: hidden;
  width: 100%;
}
.item__img .swiper__item-main img {
  width: 100%;
  height: auto;
}
.item__img .swiper__item-main .swiper-button-next__item,
.item__img .swiper__item-main .swiper-button-prev__item {
  position: absolute;
  width: 40px;
  height: 40px;
  fill: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 30;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  mix-blend-mode: difference;
}
@media (max-width: 639.999px) {
  .item__img .swiper__item-main .swiper-button-next__item,
  .item__img .swiper__item-main .swiper-button-prev__item {
    width: 30px;
    height: 30px;
  }
}
.item__img .swiper__item-main .swiper-button-next__item svg,
.item__img .swiper__item-main .swiper-button-prev__item svg {
  width: 100%;
  height: auto;
}
.item__img .swiper__item-main .swiper-button-prev__item {
  right: auto;
  left: 0px;
}
.item__img .swiper__item-main .swiper-button-prev__item svg {
  transform: rotate(180deg);
}
.item__img .swiper__item-thumb {
  overflow: hidden;
  width: 100%;
}
.item__img .swiper__item-thumb .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}
.item__img .swiper__item-thumb .swiper-slide:not(.swiper-slide-thumb-active) {
  opacity: 0.6;
}
.item__img .swiper__item-thumb .swiper-scrollbar {
  height: 4px;
  z-index: 1;
  background: #ccc;
  width: 100%;
  position: relative;
  left: 0;
}
.item__img .swiper__item-thumb .swiper-scrollbar .swiper-scrollbar-drag {
  z-index: 2;
  background: #000;
  top: 38%;
  height: 1px;
}

.item__title {
  font-weight: 900;
  font-size: clamp(1.25rem, 4.0625vw + -0.375rem, 2.875rem);
  line-height: 1.2;
  vertical-align: middle;
}

.item__payment {
  display: flex;
  align-items: center;
  gap: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  margin-top: clamp(0.3125rem, -0.78125vw + 0.625rem, 0rem);
}

.item__price {
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.item__price strong {
  font-weight: 900;
  font-size: clamp(1.25rem, 4.0625vw + -0.375rem, 2.875rem);
  line-height: 1;
}
.item__price._sale {
  color: var(--accent-color);
}

.item__regular-price {
  color: #999;
  line-height: 1;
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}

.item__option-group {
  display: flex;
  align-items: center;
  gap: clamp(0.3125rem, 0.78125vw + 0rem, 0.625rem);
}

.item__tag {
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
  display: inline-block;
  padding: 0 5px;
}
.item__tag._sale {
  background: var(--accent-color);
  color: #fff;
}
.item__tag._sold {
  background: #000;
  color: #fff;
}
.item__tag._bulk {
  background: #fff;
  color: var(--base-font-color);
}

.item__point {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.item__point dt {
  border: #000 2px solid;
  border-radius: 50%;
  line-height: 0.4;
  margin-right: 5px;
}
.item__point dt span {
  font-size: clamp(0.625rem, 1.25vw + 0.125rem, 1.125rem);
}
.item__point dd {
  white-space: nowrap;
}
.item__point dd strong {
  font-weight: 900;
  font-size: 1rem;
}

.item__shipping-free {
  display: flex;
  align-items: center;
  background: var(--base-color);
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}
.item__shipping-free > * {
  padding: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}
.item__shipping-free dt {
  line-height: 1;
  border-right: 2px solid var(--bg-color);
}

.item-option__title {
  font-weight: 900;
  font-size: clamp(1.125rem, 0.9375vw + 0.75rem, 1.5rem);
  line-height: 1.2;
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  border-bottom: solid 1px var(--border-color);
}

.item-option__table {
  width: 100%;
  display: flex;
  justify-content: space-betwfiteen;
  align-items: center;
  padding: 5px 0;
  border-bottom: solid 1px var(--border-color);
}
.item-option__table._sold {
  color: var(--border-color);
}
.item-option__table dt,
.item-option__table dd {
  vertical-align: middle;
}
.item-option__table dt > *:not(:first-child),
.item-option__table dd > *:not(:first-child) {
  margin-left: 5px;
}
.item-option__table dt {
  font-weight: 900;
  width: 35%;
  line-height: 1.2;
}
.item-option__table dt .item-option__stock {
  font-weight: 900;
  color: var(--accent-color);
  margin-left: 0;
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
}
.item-option__table dd {
  text-align: right;
  width: 65%;
}

.item-cart__btn {
  font-size: clamp(0.75rem, 0.9375vw + 0.375rem, 1.125rem);
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 81%;
  font-weight: 900;
  text-align: center;
  padding: clamp(0.1875rem, 0.46875vw + 0rem, 0.375rem) clamp(0.1875rem, 1.25vw + -0.3125rem, 0.6875rem);
  transition: background-color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1), border 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
  background: #000;
  color: #fff;
  border: 2px solid #000;
}
.item-cart__btn._sold {
  border: 2px solid var(--border-color);
  color: #000;
  background: transparent;
  transition: border-color 1000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.item-cart__btn .material-symbols-outlined {
  vertical-align: middle;
}

a.item-cart__btn:hover {
  border: 2px solid currentColor;
  color: #000;
  background: transparent;
}
a.item-cart__btn._sold:hover {
  border: 2px solid currentColor;
  color: #000;
  background: transparent;
}

.item-favorite__btn .material-symbols-outlined {
  vertical-align: middle;
  font-size: clamp(1.75rem, 1.25vw + 1.25rem, 2.25rem);
}
.item-favorite__btn.is-active .material-symbols-outlined {
  font-variation-settings: "FILL" 1;
}

.item__contents {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.item__contents > * {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
}
.item__contents h2,
.item__contents h3,
.item__contents h4,
.item__contents h5,
.item__contents h6 {
  font-weight: 900;
}
.item__contents h2 + *,
.item__contents h3 + *,
.item__contents h4 + *,
.item__contents h5 + *,
.item__contents h6 + * {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
}
.item__contents h2 {
  font-size: clamp(1.125rem, 5.625vw + -1.125rem, 3.375rem);
}
.item__contents h3 {
  font-size: clamp(1rem, 4.6875vw + -0.875rem, 2.875rem);
}
.item__contents h4 {
  font-size: clamp(1rem, 3.4375vw + -0.375rem, 2.375rem);
}
.item__contents h5 {
  font-size: clamp(0.875rem, 2.1875vw + 0rem, 1.75rem);
}
.item__contents h6 {
  font-size: clamp(0.75rem, 1.875vw + 0rem, 1.5rem);
}
.item__contents ul {
  list-style: disc;
  margin-left: 20px;
}
.item__contents ol {
  margin-left: 15px;
}
.item__contents table {
  width: 100%;
}
.item__contents table th {
  white-space: nowrap;
  font-weight: 900;
  background: var(--base-color);
}
.item__contents table th,
.item__contents table td {
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
  padding: clamp(0.625rem, 0.78125vw + 0.3125rem, 0.9375rem) clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  border: solid 1px var(--border-color);
}
.item__contents em {
  font-weight: bold;
  color: var(--accent-color);
}
.item__contents h2 {
  font-size: 1.75rem;
}
@media (max-width: 639.999px) {
  .item__contents h2 {
    font-size: 1.25rem;
  }
}
.item__contents h3 {
  font-size: 1.125rem;
}
.item__contents h4 {
  font-size: 0.875rem;
}
.item__contents h5 {
  font-size: 0.75rem;
}

.item__unisize {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
}

.item-list {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px;
}
@media (max-width: 767.999px) {
  .item-list {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 10px 0;
    gap: 10px;
  }
}
@media (max-width: 639.999px) {
  .item-list {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 5px 0;
    gap: 10px;
  }
}
.items-control + .item-list {
  margin-top: 0;
}

.item-list__detail {
  overflow: hidden;
  width: calc((100% - 100px) / 6);
}
@media (max-width: 1279.999px) {
  .item-list__detail {
    width: calc((100% - 60px) / 4);
  }
}
@media (max-width: 1023.999px) {
  .item-list__detail {
    width: calc((100% - 40px) / 3);
  }
}
@media (max-width: 767.999px) {
  .item-list__detail {
    width: calc((100% - 20px) / 3);
  }
}
@media (max-width: 639.999px) {
  .item-list__detail {
    width: calc((100% - 10px) / 2);
  }
}

.item-list__img {
  overflow: hidden;
  position: relative;
  line-height: 0;
}
.item-list__img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 2000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.item-list__img a {
  line-height: 0;
}
.item-list__img a:hover img {
  transform: scale(1.1);
}

.item-list__tag {
  margin-right: 5px;
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
}
.item-list__tag._id {
  line-height: 1.2;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  background: #000;
  color: #fff;
  margin-right: 0;
}
.item-list__tag._sale {
  display: inline-block;
  padding: 0 5px;
  background: var(--accent-color);
  color: #fff;
}
.item-list__tag._sold {
  display: inline-block;
  padding: 0 5px;
  background: #000;
  color: #fff;
}

.item-list__title {
  font-weight: 900;
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  vertical-align: middle;
}

.item__icon {
  max-width: 30px;
  height: auto;
}

.item-list__price {
  font-size: 0.75rem;
}
.item-list__price strong {
  font-weight: 900;
  font-size: 1rem;
}
.item-list__price._sale {
  color: var(--accent-color);
}

.slide-item__block {
  margin-top: clamp(1.25rem, 3.125vw + 0rem, 2.5rem);
  padding: 0 20px;
  counter-reset: rank 0;
}
@media (max-width: 767.999px) {
  .slide-item__block {
    padding: 0 10px;
  }
}
@media (max-width: 639.999px) {
  .slide-item__block {
    padding: 0 5px;
  }
}
.slide-item__block._top .item-list__tag._sold {
  background: #fff;
  color: #000;
}
.slide-item__block .swiper-slide {
  overflow: hidden;
  width: calc((100% - 100px) / 6);
  transform: translateY(10%);
  opacity: 0;
  transition: opacity 2000ms cubic-bezier(0.08, 0.82, 0.17, 1), transform 2000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.slide-item__block .swiper-slide.is-active {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 1279.999px) {
  .slide-item__block .swiper-slide {
    width: calc((100% - 60px) / 4);
  }
}
@media (max-width: 1023.999px) {
  .slide-item__block .swiper-slide {
    width: calc((100% - 40px) / 3);
  }
}
@media (max-width: 767.999px) {
  .slide-item__block .swiper-slide {
    width: calc((100% - 20px) / 3);
  }
}
@media (max-width: 639.999px) {
  .slide-item__block .swiper-slide {
    width: calc((100% - 10px) / 2);
  }
}
.slide-item__block .slide-item__controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: clamp(0.625rem, 4.6875vw + -1.25rem, 2.5rem);
}
.slide-item__block .slide-item__controller .swiper-scrollbar__top-item {
  height: 4px;
  z-index: 1;
  background: #222;
  width: calc(100% - 160px);
}
@media (max-width: 639.999px) {
  .slide-item__block .slide-item__controller .swiper-scrollbar__top-item {
    width: calc(100% - 100px);
  }
}
.slide-item__block .slide-item__controller .swiper-scrollbar__top-item .swiper-scrollbar-drag {
  z-index: 2;
  background: #fff;
  top: 38%;
  height: 1px;
}
.slide-item__block .slide-item__controller .swiper-button-next__top-item,
.slide-item__block .slide-item__controller .swiper-button-prev__top-item {
  width: 60px;
  height: 60px;
  border: solid 1px #fff;
  border-radius: 50%;
  fill: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 639.999px) {
  .slide-item__block .slide-item__controller .swiper-button-next__top-item,
  .slide-item__block .slide-item__controller .swiper-button-prev__top-item {
    width: 40px;
    height: 40px;
  }
}
.slide-item__block .slide-item__controller .swiper-button-next__top-item svg,
.slide-item__block .slide-item__controller .swiper-button-prev__top-item svg {
  width: 100%;
  height: auto;
  transform: scale(0.6);
}
.slide-item__block .slide-item__controller .swiper-button-prev__top-item svg {
  transform: rotate(180deg) scale(0.6);
}
.slide-item__block .slide-item__controller .swiper-scrollbar__check-item {
  height: 4px;
  z-index: 1;
  background: #ccc;
  width: calc(100% - 160px);
}
@media (max-width: 639.999px) {
  .slide-item__block .slide-item__controller .swiper-scrollbar__check-item {
    width: calc(100% - 100px);
  }
}
.slide-item__block .slide-item__controller .swiper-scrollbar__check-item .swiper-scrollbar-drag {
  z-index: 2;
  background: #000;
  top: 38%;
  height: 1px;
}
.slide-item__block .slide-item__controller .swiper-button-next__check-item,
.slide-item__block .slide-item__controller .swiper-button-prev__check-item {
  width: 60px;
  height: 60px;
  border: solid 1px #000;
  border-radius: 50%;
  fill: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 639.999px) {
  .slide-item__block .slide-item__controller .swiper-button-next__check-item,
  .slide-item__block .slide-item__controller .swiper-button-prev__check-item {
    width: 40px;
    height: 40px;
  }
}
.slide-item__block .slide-item__controller .swiper-button-next__check-item svg,
.slide-item__block .slide-item__controller .swiper-button-prev__check-item svg {
  width: 100%;
  height: auto;
  transform: scale(0.6);
}
.slide-item__block .slide-item__controller .swiper-button-prev__check-item svg {
  transform: rotate(180deg) scale(0.6);
}
.slide-item__block .swiper-button-lock {
  display: none !important;
}

.top-collaborate__block {
  padding-bottom: clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
}

.collaborate-list {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) clamp(0.625rem, 1.5625vw + 0rem, 1.25rem) 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 767.999px) {
  .collaborate-list {
    gap: 10px;
    margin-top: 10px;
  }
}
.collaborate-list li {
  width: calc((100% - 60px) / 4);
  font-weight: bold;
  line-height: 1;
}
@media (max-width: 767.999px) {
  .collaborate-list li {
    width: calc((100% - 20px) / 3);
  }
}
.collaborate-list li a {
  font-size: clamp(0.625rem, 1.25vw + 0.125rem, 1.125rem);
}

.topnews__block {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 clamp(0.625rem, 1.5625vw + 0rem, 1.25rem) clamp(1.25rem, 6.25vw + -1.25rem, 3.75rem);
}

.slide-news {
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
}
.slide-news li a {
  display: block;
  line-height: 1;
  font-weight: bold;
  font-size: clamp(0.625rem, 0.9375vw + 0.25rem, 1rem);
}

.cart__block,
.cart-no__block {
  margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 20px 0;
}
@media (max-width: 767.999px) {
  .cart__block,
  .cart-no__block {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 10px 0;
  }
}
@media (max-width: 639.999px) {
  .cart__block,
  .cart-no__block {
    margin: clamp(1.25rem, 3.125vw + 0rem, 2.5rem) 5px 0;
  }
}

.cart-no__block {
  text-align: center;
}

.cart__header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
}
@media (max-width: 767.999px) {
  .cart__header {
    gap: 10px;
  }
}
.cart__header .cart__title {
  font-weight: 900;
  width: calc((75% - 80px) / 3);
}
@media (max-width: 767.999px) {
  .cart__header .cart__title {
    width: calc((75% - 40px) / 3);
  }
}
.cart__header .cart__title._count {
  width: 15%;
}
.cart__header .cart__title._delete {
  width: 10%;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
}
@media (max-width: 767.999px) {
  .cart-item {
    gap: 10px;
  }
}
.cart-item > * {
  width: calc((75% - 80px) / 3);
}
@media (max-width: 767.999px) {
  .cart-item > * {
    width: calc((75% - 40px) / 3);
  }
}
.cart-item > *.cart-item__count {
  width: 15%;
}
.cart-item > *.cart-item__delete {
  width: 10%;
}
.cart-item .cart-item__img img {
  width: 100%;
  max-width: 200px;
}
.cart-item .cart-item__title {
  font-weight: 900;
}
.cart-item .cart-item__option,
.cart-item .cart-item__point {
  font-size: 0.75rem;
}
@media (max-width: 767.999px) {
  .cart-item .cart-item__option,
  .cart-item .cart-item__point {
    font-size: 0.625rem;
  }
}
.cart-item .cart-item__count input[type=number] {
  width: 100%;
  text-align: center;
  padding: clamp(0.3125rem, 0.78125vw + 0rem, 0.625rem) clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  border: 1px solid var(--border-color);
  font-size: 1rem;
}
@media (max-width: 767.999px) {
  .cart-item .cart-item__count input[type=number] {
    font-size: 0.625rem;
  }
}
.cart-item .cart-item__count .basic__btn {
  text-align: left;
  margin-top: clamp(0.3125rem, 0.78125vw + 0rem, 0.625rem);
}
.cart-item .cart-item__count .basic__btn a {
  text-align: center;
  padding: clamp(0.3125rem, 0.78125vw + 0rem, 0.625rem) clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  width: 100%;
}
@media (max-width: 767.999px) {
  .cart-item .cart-item__count .basic__btn a {
    font-size: 0.625rem;
  }
}
.cart-item .cart-item__price {
  font-size: clamp(0.625rem, 0.3125vw + 0.5rem, 0.75rem);
}
.cart-item .cart-item__price strong {
  font-size: 1rem;
  font-weight: bold;
}

.cart-total__block {
  display: flex;
  justify-content: right;
  align-items: center;
}
.cart-total__block dt {
  font-weight: bold;
  margin-right: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem);
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.cart-total__block dd {
  font-size: clamp(0.75rem, 0.625vw + 0.5rem, 1rem);
}
.cart-total__block dd strong {
  font-weight: bold;
  font-size: clamp(1.25rem, 4.0625vw + -0.375rem, 2.875rem);
  line-height: 1;
}

.cart-bulk__block {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 20px;
}
@media (max-width: 639.999px) {
  .cart-bulk__block {
    display: block;
  }
}
@media (max-width: 639.999px) {
  .cart-bulk__block .cart-bulk__title {
    font-size: 0.75rem;
    text-align: right;
  }
}
.cart-bulk__block .cart-total__block dd strong {
  color: var(--accent-color);
}

.cart-control__block .amazon > * {
  margin: clamp(0.625rem, 1.5625vw + 0rem, 1.25rem) auto 0;
}
@media (max-width: 639.999px) {
  .cart-control__block .amazon > * {
    width: 60% !important;
  }
}

@media (max-width: 639.999px) {
  .no__under-sm {
    display: none;
  }
}

@media (max-width: 767.999px) {
  .no__under-md {
    display: none;
  }
}

@media (max-width: 1023.999px) {
  .no__under-lg {
    display: none;
  }
}

@media (min-width: 640px) {
  .no__over-sm {
    display: none;
  }
}

@media (min-width: 768px) {
  .no__over-md {
    display: none;
  }
}

@media (min-width: 1024px) {
  .no__over-lg {
    display: none;
  }
}

@media (min-width: 1280px) {
  .no__over-xl {
    display: none;
  }
}

.js-fadein {
  transform: translateY(10%);
  opacity: 0;
  transition: opacity 2000ms cubic-bezier(0.08, 0.82, 0.17, 1), transform 2000ms cubic-bezier(0.08, 0.82, 0.17, 1);
}
.js-fadein.is-active {
  opacity: 1;
  transform: translateY(0);
}
